// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deploy-game-js": () => import("./../../../src/pages/deploy-game.js" /* webpackChunkName: "component---src-pages-deploy-game-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-oauth-sandbox-index-tsx": () => import("./../../../src/pages/docs/oauth/sandbox/index.tsx" /* webpackChunkName: "component---src-pages-docs-oauth-sandbox-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-documentation-index-js": () => import("./../../../src/templates/documentation/index.js" /* webpackChunkName: "component---src-templates-documentation-index-js" */)
}

