import React from 'react'
import { createGlobalStyle } from "styled-components"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto, sans-serif;
  }
`

export const wrapRootElement = ({ element }) => (
  <>
    { element }
    <GlobalStyle />
  </>
)
